import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./services/auth.service";

@Injectable({
  providedIn: "root",
})
export class CanActivateRouteGuard {
  subRoutesGranted = [];

  constructor(public authService: AuthService, public router: Router) {
    this.subRoutesGranted["CapoArea"] = ["agenti", "account", "prodotti"];
  }

  checkRole(currentRole, next): boolean {
    let granted = false;
    //Admin has permissions for all paths
    if (currentRole == "Admin") return true;

    if (!Array.isArray(currentRole)) return false;
    //array checked
    let nextPath = next.routeConfig.path.split("/")[0];
    if (currentRole.includes("CapoArea")) {
      let pathsGranted: string[] = this.subRoutesGranted["CapoArea"];
      if (pathsGranted && pathsGranted.indexOf(nextPath) !== -1) granted = true;
    }
    return granted;
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      let isValid = await this.authService.checkToken();
      let currentRole = await this.authService.getUserInfo()?.role;
      let roleValidity = this.checkRole(currentRole, next);
      if (isValid && roleValidity) {
        resolve(true);
      } else {
        this.router.navigate(["/pages/login"]);
        resolve(false);
      }
    });
  }
}
