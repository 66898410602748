import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutes } from "./app.routing";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CanActivateRouteGuard } from "./can-activate-route.guard";
import { Interceptor } from "./interceptors/interceptor";
import { roleGuard } from "./Role.guard";
import { ProductsCatalogListComponent } from "./products-catalog/products-catalog-list/products-catalog-list.component";

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    NgbModule,
    ToastrModule.forRoot(), // ToastrModule added
    ComponentsModule,
  ],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
    },
    CanActivateRouteGuard,
    roleGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
