import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  private loaderDisabled: boolean = false;

  constructor() {}

  private loadStack: number = 0;

  show() {
    if (this.loaderDisabled) return;
    else {
      this.loadStack++;
      setTimeout(() => {
        this._loading.next(true);
      });
    }
  }

  hide() {
    if (this.loadStack > 0) {
      this.loadStack--;
    }
    if (this.loadStack === 0) {
      this._loading.next(false);
    }
  }

  disableLoad(): void {
    this.loaderDisabled = true;
    this.loadStack = 0;
    this._loading.next(false);
  }

  enableLoad(): void {
    this.loaderDisabled = false;
  }
}
